import React from "react"
import styled from 'styled-components'
import AppStore from '../assets/app-store.svg';
import PaperBagsSvg from '../assets/paper-bags.svg'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header";
import Section from "../components/UI/Section"
import ScreenTwo from '../images/bag-chat-2.png';
import ScreenOne from '../images/bag-chat-1.png';

const StyledAppStore = styled(AppStore)`
`
const Container = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  position: relative;
`

const StyledPaperBag = styled(PaperBagsSvg)`
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  opacity: 0.25;
  top: 40px;
  left: 30px;
  z-index: -1;
`
const StyledH1 = styled.h1`
  font-size: 48px;
  color: #fff;
  margin-bottom: 0px;
`
const StyledH2 = styled.h2`
font-size: 32px;
color: #fff;
`

const StyledIntro = styled.div`
  float: left;
`

const StyledImage = styled.img`
  width: 250px;
  position: absolute;
  right: 50px;
`
const StyledImageContainer = styled.div`
  float: right;
  position: relative;
  width:50%
`


const IndexPage = () => (
  <Layout>
    <Container>
      <SEO title="Download Bag Chat" />
      <Header />
      <Section className="container">
        <StyledIntro className="xs-full">
          <StyledH1>Talk to people</StyledH1>
          <StyledH2>Send us feedback at <a href="mailto:yo@bag.chat" target="_top">yo@bag.chat</a></StyledH2>
          <a href="https://apps.apple.com/us/app/bag-chat/id1462825951?ls=1" target="new_page"><StyledAppStore /></a>
          <StyledH1>Android App Coming Soon!</StyledH1>
          
        </StyledIntro>
        
        <StyledImageContainer className="xs-full flex-container">
          <StyledImage src={ScreenTwo} className="xs-center"/>
          <StyledImage src={ScreenOne} style={{right: "-30px", top: "80px"}} className="xs-center xs-center-2"/>
        </StyledImageContainer>
      </Section>
      <StyledPaperBag />
    </Container>
  </Layout>
)

export default IndexPage
